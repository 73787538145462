import LocomotiveScroll from 'locomotive-scroll';
import {gsap} from 'gsap';
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {ScrollToPlugin} from "gsap/ScrollToPlugin";
import {DOM, globals} from './_variables';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

const scroll = new LocomotiveScroll();

export default {
  homeHeroParallax: () => {
    const hero = document.querySelector('.main-home > .bg-texture')

    if (hero) {
      const images = hero.querySelectorAll('img'),
        titleWrap = hero.querySelector('.title-wrap')

      gsap.to(images[0], {
        scrollTrigger: {
          trigger: hero,
          start: "top",
          end: '50%',
          scrub: true,
          toggleActions: 'play reverse restart reverse',
        },
        y: '10%'
      })

      gsap.to(images[1], {
        scrollTrigger: {
          trigger: hero,
          start: "top",
          end: '70%',
          scrub: true,
          toggleActions: 'play reverse restart reverse',
        },
        y: '20%'
      })

      gsap.to(images[2], {
        scrollTrigger: {
          trigger: hero,
          start: "top",
          end: '110%',
          scrub: true,
          toggleActions: 'play reverse restart reverse',
        },
        y: '15%',
      })

      gsap.to(images[3], {
        scrollTrigger: {
          trigger: hero,
          start: "15%",
          end: 'bottom',
          scrub: true,
          toggleActions: 'play reverse restart reverse',
        },
        y: '-20%'
      })

      gsap.to(images[4], {
        scrollTrigger: {
          trigger: hero,
          start: "15%",
          end: 'bottom',
          scrub: true,
          toggleActions: 'play reverse restart reverse',
        },
        y: '-20%'
      })

      gsap.to(images[5], {
        scrollTrigger: {
          trigger: hero,
          start: "15%",
          end: '110%',
          scrub: true,
          toggleActions: 'play reverse restart reverse',
        },
        y: '-20%',
      })

      gsap.to(titleWrap, {
        scrollTrigger: {
          trigger: hero,
          start: "top",
          end: '110%',
          scrub: true,
          toggleActions: 'play reverse restart reverse',
        },
        y: '50%',
      })
    }
  },
  homeScrollingSection: () => {
    const sections = document.querySelectorAll('.scrolling-sections__section');

    if (sections.length && window.innerWidth > 767) {
      for (const section of sections) {
        const card = section.querySelector('.slider_card'),
          background = section.querySelector('.scrolling-sections__section__background'),
          addActiveClass = () => {
            card.classList.add('slider_card--active')
          }

        ScrollTrigger.create({
          trigger: section,
          start: "top top",
          pin: true,
          pinSpacing: false,
        });

        gsap.to(card, {
          scrollTrigger: {
            trigger: card,
            start: "-150%",
            end: '100%',
            onEnter: () => addActiveClass(),
          },
          transform: 'translateX(0)',
          duration: .7,
          opacity: 1,
        })

        gsap.to(background, {
          scrollTrigger: {
            trigger: section,
            scrub: true,
            start: "-100%",
            end: 'bottom',
          },
          backgroundPositionY: '-33vh',
          ease: "none"
        })

        gsap.to(background, {
          scrollTrigger: {
            trigger: section,
            scrub: true,
            start: "-70%",
            end: '15%',
          },
          opacity: 1,
        })
      }
    }
  },
  categoryScrollingSection: () => {
    const parallaxSections = document.querySelectorAll('.category-parallax > div')
    const revealProducts = document.querySelectorAll('.category-sequence-image')

    if (parallaxSections.length && window.innerWidth > 767) {
      for (const section of parallaxSections) {
        const addActiveClass = () => {
          section.classList.add('category-parallax__img--visible')
        }

        ScrollTrigger.create({
          trigger: section,
          start: "top top",
          pin: true,
          pinSpacing: false,
        });

        gsap.to(section.querySelectorAll('.parallax-reveal'), {
          scrollTrigger: {
            trigger: section.querySelector('.parallax-reveal'),
            scrub: true,
            start: "top 50%",
            end: '-50%%',
          },
          duration: 1,
          stagger: .2,
          y: 0,
          opacity: 1,
        })
      }
    }

    if (revealProducts.length) {
      for (const product of revealProducts) {
        gsap.to(product, {
          scrollTrigger: {
            trigger: product,
            scrub: false,
            start: "-110%",
            end: '25%%',
            once: true
          },
          y: 0,
          rotate: 0,
          opacity: 1,
          duration: 2,
          ease: "expo.out"
        })
      }
    }
  },
  accordions: () => {
    const accordions = DOM.html.querySelectorAll('.accordion');

    if (accordions.length) {
      for (const accordion of accordions) {
        const
          title = accordion.querySelector('.accordion__title'),
          collapsible = accordion.querySelector('.accordion__collapsible')
        let isOpen = false

        title.addEventListener('click', () => {
          if (!isOpen) {
            gsap.to(collapsible, {
              onStart: () => {
                title.classList.add('accordion__title--active')
              },
              height: 'auto'
            })
          } else {
            gsap.to(collapsible, {
              onStart: () => {
                title.classList.remove('accordion__title--active')
              },
              height: 0
            })
          }

          isOpen = !isOpen
        })
      }
    }
  },
  smoothScroll: () => {
    const scroll = new LocomotiveScroll({
      el: document.querySelector(".smooth-scroll"),
      smooth: true // was true
    });
  },
  toggleMenu: () => {
    const
      wrapMenu = document.getElementById('wrap-menu'),
      menuItems = document.querySelectorAll('[data-menu]'),
      hiders = document.querySelectorAll('.close-open-menu'),
      mobileToggle = document.querySelector('.mobile-toggle'),
      closemenu = document.querySelector('.close'),
      openmenu = document.querySelector('.open'),


      getRelated = (element) => {
        return document.getElementById(element.getAttribute('data-menu'))
      },
      getActiveMenu = () => {
        return document.querySelector('.menu-active')
      },
      closeMenu = () => {
        const activeMenu = getActiveMenu()
        DOM.html.style.removeProperty('overflow')
        DOM.header.classList.remove('top-navigation--open')

        if (activeMenu) {
          activeMenu.classList.remove('menu-active')
          getRelated(activeMenu).classList.remove('menu-products--active')
        }
        wrapMenu.classList.remove('menu-open')
      },
      openMenu = (element) => {
        if (getActiveMenu()) {
          closeMenu()
        }
        DOM.header.classList.add('top-navigation--open')
        getRelated(element).classList.add('menu-products--active')
        element.classList.add('menu-active')
        wrapMenu.classList.add('menu-open')
      }

    for (const item of menuItems) {
      let isOpen = false

      if (!globals.hasTouchScreen()) {
        let delay = 200,
          setTimeoutConst;

        item.addEventListener('mouseenter', () => {
          setTimeoutConst = setTimeout(function () {
            openMenu(item)

            isOpen = true
          }, delay)
        })

        item.addEventListener('mouseleave', () => {
          if (window.innerWidth > 767 && !isOpen) {
            clearTimeout(setTimeoutConst)

            closeMenu()

            isOpen = false
          }
        })

        for(const hider of hiders) {
          hider.addEventListener('mouseenter', () => {
            if (window.innerWidth > 767) {
              clearTimeout(setTimeoutConst)

              closeMenu()

              isOpen = false
            }
          })
        }
      }
    }

    mobileToggle.addEventListener('click', () => {
      DOM.header.classList.toggle('top-navigation--open')
      wrapMenu.classList.toggle('wrap-menu--active')
      closemenu.classList.toggle('hidden')
      openmenu.classList.toggle('hidden')
    })
  },
  turnTop: () => {
    const btn = document.getElementById('turn-top')

    gsap.to(btn, {
      scrollTrigger: {
        trigger: DOM.html,
        start: '50vh',
        end: 'bottom',
        toggleActions: 'play reverse restart reverse',
      },
      autoAlpha: 1,
      duration: .5
    })

    btn.addEventListener('click', () => {
      gsap.to(window, {duration: 1, scrollTo: {y: 0, x: 0}});
    })


  }
}
