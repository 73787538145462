// postcss stylesheets
import './../styles/_tailwind.pcss'
import './../styles/main.pcss'

'use strict';

import animations from './_animations'
import forms from './_forms'
import global from './_global';
import lazyLoad from './_lazy-load';
import slider from "./_sliders";

document.addEventListener("DOMContentLoaded", () => {
  global.pageLoadedChecker()
  global.getSreenSize()

  //animations.smoothScroll();
  animations.homeHeroParallax()
  animations.homeScrollingSection()
  animations.categoryScrollingSection()
  animations.accordions()
  animations.toggleMenu()
  animations.turnTop()

  // forms
  forms.contacts()

  // plugins
  lazyLoad.init()
  slider.galleries()
});
