import Glide from '@glidejs/glide'
import {DOM} from "./_variables";

export default {
    galleries: () => {

      const sliders = document.querySelectorAll('.glide-slider');

      if (sliders.length) {
        for (const slider of sliders) {

          const glide = new Glide(slider, {
            type: 'carousel',
            autoplay: 4000,
            perView: 2,
            focusAt: 'center',
            peek: {
              before: 100,
              after: 100,
            },
            breakpoints: {
              800: {
                perView: 2
              },
              767: {
                perView: 1,
                peek: {
                  before: 50,
                  after: 50,
                }
              }
            }
          })

          glide.mount()
        }

      }
    }
}
