import {gsap} from 'gsap';
import {DOM} from './_variables';

export default {
  contacts: () => {
    const
      formPage = DOM.html.querySelector('.main-contacts')

    if (formPage) {
      const
        form = formPage.querySelector('form'),
        showName = formPage.querySelector('.contact-form-confirm__name'),
        showMessage = formPage.querySelector('.contact-form-confirm__message'),
        showEmail = formPage.querySelector('.contact-form-confirm__email'),
        name = form.querySelector('#input-name'),
        surname = form.querySelector('#input-surname'),
        email = form.querySelector('#input-email'),
        city = form.querySelector('#input-city'),
        typology = form.querySelector('#select-typology'),
        message = form.querySelector('#input-message'),
        acceptance = form.querySelector('#acceptance')

      form.addEventListener('submit', (event) => {
        event.preventDefault()

        const
          baseUrl = window.location.origin,
          fetchUrl = baseUrl + '/wp-json/contact-form-7/v1/contact-forms/253/feedback',
          formData = new FormData();

        const data = {
          "your-name": name.value,
          "your-surname": surname.value,
          "your-email": email.value,
          "city": city.value,
          "menu-340": typology.value,
          "user-privacy": acceptance.value,
          "your-message": message.value,
        };

        for (const name in data) {
          formData.append(name, data[name]);
        }

        fetch(fetchUrl, {
          method: 'POST',
          body: formData,
        })
          .then(response => response.json())
          .then(data => {
            if (data['status'] === 'mail_sent') {
              gsap.to('.contact-form-to-hide', {
                autoAlpha: 0,
                duration: .4,
                onComplete: () => {
                  gsap.to('.contact-form-to-hide', {
                    display: 'none',
                    duration: 'none'
                  })

                  showName.textContent = name.value
                  showMessage.textContent = message.value
                  showEmail.textContent = email.value

                  gsap.to('.contact-form-confirm', {
                    autoAlpha: 1,
                    duration: .7,
                  })
                }
              })
            }
          })
          .catch((error) => {
            console.error('Error:', error);
          });
      })
    }
  }
}
